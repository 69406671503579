

































































































































































































































































































































































































































import { Component, Watch } from 'vue-property-decorator';
import * as ExcelJS from 'exceljs/dist/exceljs';
import * as FileSaver from 'file-saver';
import ScopeComponent from '@/components/scope.component.vue';
import QuestionProvider from '@/providers/questions.provider';
import UTILS from '@/shared/utils';
import DurationComponent from '@/shared/duration.component.vue';
import IlosComponets from '@/shared/ilos-add-component.vue';
import SubjectFlexibleILOs from '../subjects/SubjectFlexibleILOs.component.vue';
import SubjectProvider from '../../providers/subject.provider';

import {
  APPIDS,
  TABLE_HEADER_CLASS,
  FILE_VALIDATION_ERRORS,
  QUESTIONS_IMPORT_TYPES_CODES,
} from '../../enums';
import ExportQuestionsStatusReportXLSXMixin from '../../mixins/exportQuestionsStatusReportXLSX.mixin';
import SelectedCourse from '../SelectedCourse.component.vue';
import { ServerTranslation } from '../../types/course.type';
import {
  ImportQuestionResult, ImportFiltersResponse, Language, BasicFilterInput, TypeIloInput,
} from '../../types/questions.type';
import ImportQuestionsDialogComponent from './importQuestionsDialog.component.vue';
import Toaster from '../../mixins/toaster.mixin';
import { Lang } from '../../types/general.type';
import { HeaderILos, FlexibleILOs, FlexibleILOsData } from '../../types/ilos.type';

@Component({
  components: {
    SelectedCourse,
    ScopeComponent,
    DurationComponent,
    ImportQuestionsDialogComponent,
    SubjectFlexibleILOs,
    IlosAddEdit: IlosComponets,
  },
})
export default class ImportQuestions extends Toaster {
  appId: string = APPIDS.CREATE_QUESTIONS;

  courseId: string = this.$route?.params.courseId;

  headerClass = TABLE_HEADER_CLASS;

  importTypesCodes = QUESTIONS_IMPORT_TYPES_CODES;

  subjects: { text: string; value: string }[] = [];

  isSCU = false;

  ilosLoading = false;

  subjectILOS: HeaderILos | FlexibleILOsData[] = {};

  languagesData: { name: ServerTranslation; id: string; value: string }[] = [];

  difficulty = 0;

  time = '';

  degree = 0;

  selectedSubject = '';

  selectedLanguage = '';

  selectedIlosHeader = '';

  subjectFlexibleIlosHeaders: BasicFilterInput[] = [];

  flexibleILOs: FlexibleILOsData[] = [];

  isFlexibleILOs!: boolean;

  fixedIlos = {
    '1.1.': [],
    '1.2.': [],
    '1.3.': [],
    '1.4.': [],
  }

  fileTypes = [
    { text: 'Moodle xml', value: this.importTypesCodes.MOODEL },
    { text: 'Qmans xml', value: this.importTypesCodes.QMANS_XML },
    { text: 'Qmans Document', value: this.importTypesCodes.QMANS_DOC },
    { text: 'Qmans Excel', value: this.importTypesCodes.QMANS_EXCEL },
    // { text: 'Molex Excel', value: this.importTypesCodes.MOLEX_EXCEL },
    { text: 'Molex Txt', value: this.importTypesCodes.MOLEX_JSON },
  ];

  validForm = true;

  ExcelTemplate: ExportQuestionsStatusReportXLSXMixin = new ExportQuestionsStatusReportXLSXMixin();

  selectedFileType: string = this.fileTypes[0].value;

  error: ServerTranslation | string = '';

  importError: ServerTranslation | null = null;

  $refs!: {
    file: HTMLInputElement;
    form: HTMLFormElement;
    importQuestionsDialog: ImportQuestionsDialogComponent;
  };

  selectRules = {
    degree: [
      (val: number) => val >= 0.1 || this.$t('validations.minValue', { min: 0.1 }),
      (val: number) => val <= 100 || this.$t('validations.maxValue', { max: 100 }),
    ],
    required: [(v) => !!v || this.$t('validations.required')],
  };

  addedQuestion: Array<ImportQuestionResult> = [];

  faildQuestion: Array<ImportQuestionResult> = [];

  importFilename = '';

  loading = false;

  ilos?: HeaderILos | FlexibleILOsData[];

  // disableItem(item) {
  //   if(this.isSCU) {
  //     this.selectedFileType = this.fileTypes[3].value;
  //     return item.text !== 'Qmans Excel';
  //   }
  // }

  updateFlexibleILOs(ilos: FlexibleILOs) {
    this.flexibleILOs = JSON.parse(JSON.stringify(ilos));
    this.$emit('value-changed', this.flexibleILOs);
    this.ilos = this.flexibleILOs;
  }

  get subjectIlosHeaders() {
    return Object.keys(this.subjectILOS).map((iloHeaderId) => ({
      id: iloHeaderId,
      name: this.$options.filters?.servertranslate(
        this.subjectILOS[iloHeaderId][0].header
      ),
    })) as BasicFilterInput[];
  }

  get selectedHeaderIlos() {
    return this.selectedIlosHeader
      ? { [this.selectedIlosHeader]: this.subjectILOS[this.selectedIlosHeader] }
      : {};
  }

  get languages(): Language[] {
    return this.languagesData?.map((lang: Language) => ({
      ...lang,
      translatedName: this.$options.filters?.servertranslate(lang.name),
    }));
  }

  get faildHeaders() {
    return [
      {
        text: this.$t('tableHeaders.index'),
        align: 'center',
        sortable: false,
        value: 'questionNo',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.reason'),
        align: 'center',
        sortable: false,
        value: 'error',
        class: this.headerClass,
      },
    ];
  }

  get succesHeader() {
    return [
      {
        text: this.$t('tableHeaders.index'),
        align: 'center',
        sortable: false,
        value: 'questionNo',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.questionTitle'),
        align: 'center',
        sortable: false,
        value: 'title',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.questionType'),
        align: 'center',
        sortable: false,
        value: 'type',
        class: this.headerClass,
      },
    ];
  }

  get footerOptions() {
    return {
      'items-per-page-text': this.$t('RowsPerPage'),
      'items-per-page-all-text': this.$t('ALL'),
    };
  }

  async mounted() {
    this.getcourseSubjects();
    this.onSubjectChange(this.selectedSubject);
  }

  size() {
    const size = {
      xs: 'x-small',
      sm: 'small',
      lg: 'large',
      xl: 'x-large',
    }[this.$vuetify.breakpoint.name];
    return size ? { [size]: true } : {};
  }

  async getcourseSubjects() {
    try {
      const filtersResponse: ImportFiltersResponse = await QuestionProvider.getImportFilters(
        this.courseId,
        this.appId,
      );

      this.isSCU = filtersResponse.isSCU || false;

      this.subjects = filtersResponse.subjects.map((subject) => ({
        text: subject.name ?? '',
        value: subject.id ?? '',
      }));

      this.languagesData = filtersResponse.languages.map((lang) => ({
        name: lang.name ?? {},
        id: lang.id ?? '',
        value: lang.subname ?? '',
      }));
    } catch (err) {
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  async uploadSelectedQuestions(selectedQuestions) {
    try {
      this.loading = true;
      const uploadQuestionsResult = await QuestionProvider.uploadQuestions(
        this.appId,
        this.courseId,
        this.selectedSubject,
        selectedQuestions,
      );
      this.loading = false;
      this.addedQuestion = uploadQuestionsResult.succededQuestions || [];
      this.addedQuestion = this.addedQuestion.sort((a, b) => Number(a.questionNo) - Number(b.questionNo)) || [];
      this.faildQuestion = uploadQuestionsResult.questions || [];
      this.faildQuestion = this.faildQuestion.sort((a, b) => Number(a.questionNo) - Number(b.questionNo)) || [];
      this.successToaster(uploadQuestionsResult.message as Lang, { duration: 5000 });
    } catch (err) {
      this.loading = false;
      this.error = err as Lang;
    }
  }

  onImportBtnClick() {
    if (!this.$refs.form.validate()) {
      return;
    }
    this.$refs.file.click();
  }

  restSearchResults() {
    this.error = '';
    this.importError = null;
    this.addedQuestion = [];
    this.faildQuestion = [];
  }

  async onImportFileChange(event, fileType) {
    try {
      this.restSearchResults();
      const questionsFile = event.target.files[0];
      this.$refs.file.value = '';
      this.importFilename = questionsFile.name;

      let fileValidations: { valid?: boolean; type?: string } = {};
      if (fileType === 'xml') {
        fileValidations = UTILS.VALIDATE_XML_FILE(questionsFile);
      } else if (fileType === 'txt') {
        fileValidations = UTILS.VALIDATE_TXT_FILE(questionsFile);
      } else {
        fileValidations = fileType === 'doc'
          ? UTILS.VALIDATE_DOC_FILE(questionsFile)
          : UTILS.VALIDATE_XLSX_FILE(questionsFile);
      }

      if (!fileValidations.valid) {
        if (fileValidations.type === FILE_VALIDATION_ERRORS.SIZE) {
          this.error = this.$t('validations.invalidFileSize', {
            max: 5,
          });
        } else {
          this.error = this.$t('validations.invalidFileType');
        }
        return;
      }

      if (this.isSCU && this.selectedFileType === this.importTypesCodes.QMANS_EXCEL && !this.ilos) {
        this.error = this.$t('validations.CHOOSE_ONE_ILOS_AT_LEAST_VALIDATION');
        return;
      }

      this.loading = true;

      const result = await QuestionProvider.importQuestion(
        this.appId,
        questionsFile,
        this.selectedFileType,
        this.selectedSubject,
        this.courseId,
        this.selectedLanguage,
        this.degree,
        this.time,
        this.difficulty,
        this.formalizeReqIlos() || [],
      );
      this.loading = false;
      if (fileType === 'doc') {
        this.$refs.importQuestionsDialog.open(
          this.getSubjectData(this.selectedSubject),
          this.getLanguageData(this.selectedLanguage),
          result.difficultyLevel || {},
          result.validQuestions || [],
          result.failedQuestions || [],
        );
        return;
      }

      this.addedQuestion = result.succededQuestions || [];
      this.addedQuestion = this.addedQuestion.sort((a, b) => Number(a.questionNo) - Number(b.questionNo)) || [];
      this.faildQuestion = result.questions || [];
      this.faildQuestion = this.faildQuestion.sort((a, b) => Number(a.questionNo) - Number(b.questionNo)) || [];
    } catch (err) {
      this.importError = err;
      this.loading = false;
    }
  }

  getSubjectData(subjectId) {
    return this.subjects.find((subject) => subject.value === subjectId) || { text: '', value: '' };
  }

  getLanguageData(languageSubname) {
    return this.languagesData.find((lang) => lang.value === languageSubname) || {};
  }

  provideDocTemplatesFile() {
    const link = document.createElement('a');
    link.href = '/files/qmans-document.docx';
    link.download = 'Qmans_Document_Templates.docx';
    link.dispatchEvent(new MouseEvent('click'));
  }

  provideXLXSTemplatesFile() {
    const data = [
      [
        'Text Of Mcq Question ',
        '1,5',
        'first answer',
        'second answer',
        'third answer',
        'fourth answer',
        'fifth answer',
      ],
      [
        'Text Of Mcq Question ',
        '4',
        'first answer',
        'second answer',
        'third answer',
        'fourth answer',
        'fifth answer',
      ],
    ];

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(' Xlxs Template', {
      views: [{ showGridLines: false }],
      properties: { defaultColWidth: 80 },
    });

    data.forEach((d) => {
      worksheet.addRow(d);
    });
    workbook.xlsx.writeBuffer().then((SavedData) => {
      const blob = new Blob([SavedData], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(blob, 'XlxsTemplate.xlsx');
    });
  }

  subjectChanged() {
    this.$emit('subjectChanged');
    this.resetSelectedIlosHeader();
  }

  resetSelectedIlosHeader() {
    this.selectedIlosHeader = '';
  }

  updateSubjectFlexibleIlosHeaders() {
    const selectedIlos = [] as BasicFilterInput[];
    const jsonIlos = JSON.parse(JSON.stringify(this.subjectILOS));
    jsonIlos.forEach((subItem) => {
      selectedIlos.push({
        id: subItem.iloId,
        name: subItem.name,
      });
    });
    this.subjectFlexibleIlosHeaders = selectedIlos;
  }

  async fetchSubjectIlos(subjectId, keepQuestionIlos = false) {
    try {
      this.ilosLoading = true;
      const { ilos, isFlexibleILOs } = await SubjectProvider.getCourseIlos(
        this.appId,
        this.courseId,
        subjectId,
      );

      this.fixedIlos = {
        '1.1.': [],
        '1.2.': [],
        '1.3.': [],
        '1.4.': [],
      };

      this.ilosLoading = false;
      this.isFlexibleILOs = isFlexibleILOs;

      if (isFlexibleILOs) {
        this.subjectILOS = ilos as FlexibleILOsData[];
        this.ilos = [];
      } else {
        this.subjectILOS = this.filterSubjectILOS(ilos as HeaderILos);
      }
      this.updateSubjectFlexibleIlosHeaders();

      if (!keepQuestionIlos) {
        // this.clearQuestionIlos();
      }
    } catch (err) {
      this.ilosLoading = false;
    }
  }

  filterSubjectILOS(ilos: HeaderILos) {
    return Object.keys(ilos as Record<string, HeaderILos>).reduce(
      (acc, iloHeader) => {
        if (ilos[iloHeader]?.length) {
          return { ...acc, [iloHeader]: ilos[iloHeader] };
        }
        return acc;
      },
      {},
    );
  }

  @Watch('selectedSubject')
  onSubjectChange(newVal) {
    if (newVal) {
      this.fetchSubjectIlos(newVal);
    } else {
      this.resetIlos();
    }
  }

  resetIlos() {
    if (this.subjectILOS) {
      Object.keys(this.subjectILOS).forEach((key) => {
        this.subjectILOS[key] = [];
      });
    }
    // this.clearQuestionIlos();
  }

  @Watch('selectedIlosHeader')
  onSelectedIlosHeaderChange(newIlosHeader) {
    if (this.isFlexibleILOs) {
      // const nodes = JSON.parse(JSON.stringify(this.subjectILOS));
      const nodes = this.subjectILOS as FlexibleILOsData[];
      for (let i = 0; i < nodes.length; i += 1) {
        const newNode = JSON.parse(JSON.stringify(nodes[i]));
        if (newNode.iloId === newIlosHeader) {
          if (newNode.children.length === 0) {
            newNode.selected = true;
          } else {
            this.setAllSelectedFalse(newNode);
          }
          this.flexibleILOs = Array.from([newNode as FlexibleILOsData]);
          // if (newNode.children.length === 0 && newNode.selected) {
          //   this.$emit('value-changed', this.flexibleILOs);
          // }
        }
      }
      this.$emit('value-changed', this.flexibleILOs);
      this.ilos = this.flexibleILOs;
    } else if (this.fixedIlos && this.fixedIlos) {
      Object.keys(this.fixedIlos as Record<string, HeaderILos>).forEach(
        (iloHeader) => {
          if (iloHeader !== newIlosHeader && this.fixedIlos) {
            this.fixedIlos[iloHeader] = [];
          }
        },
      );
      this.ilos = this.fixedIlos;
    }
  }

  setAllSelectedFalse(obj) {
    const newObj = obj;
    newObj.selected = false;

    if (newObj.children) {
      newObj.children = newObj.children.map((child) => this.setAllSelectedFalse(child)); // Recursively call the function for each child
    }
    return newObj;
  }

  ilosHeaderIdChanged() {
    this.$emit('ilosHeaderIdChanged', this.selectedIlosHeader);
  }

  formalizeReqIlos() {
    if (this.isSCU && this.selectedFileType === this.importTypesCodes.QMANS_EXCEL) {
      if (this.isFlexibleILOs) {
        return this.ilos;
      }
      return Object.keys(this.ilos as object).reduce(
        (ilosAcc: TypeIloInput[], key) => {
          if (this.ilos) {
            return [
              ...ilosAcc,
              ...this.ilos[key].map((ilo) => ({
                iloId: ilo.iloId,
                subIloId: ilo.subIloId ? ilo.subIloId : null,
              })),
            ];
          }
          return ilosAcc;
        },
        [],
      );
    }

    return [];
  }
}
