












































































































































































































































































































































import { Component } from 'vue-property-decorator';
import DialogComponent from '../dialog.component.vue';
import { QUESION_TYPES, SUPPORTED_LANGS } from '../../enums';
import MathJaxMixin from '../../mixins/mathJax.mixin';
import { ImportNotValidQuestions, ImportValidQuestions } from '../../types/exam.type';
import { Difficulty } from '../../types/questions.type';
import { ServerTranslation } from '../../types/course.type';
import Utils from '../../shared/utils';

@Component({
  components: {
    DialogComponent,
  },
})
export default class ImportQuestionsDialogComponent extends MathJaxMixin {
  headerClass = 'grey darken-4 white--text pa-3 qb-table__header';

  questionTypesKeys = QUESION_TYPES;

  loading = false;

  showSaveSelectedConfirm = false;

  showExportQuestionsDialog = false;

  validQuestions: ImportValidQuestions[] = [];

  notValidQuestions: ImportNotValidQuestions[] = [];

  courseId = this.$route.params.courseId;

  subjectData: { text: string; value: string } = { text: '', value: '' };

  diffecultyLevel: Difficulty = {};

  languageData: { name?: ServerTranslation; id?: string; value?: string } = {};

  selected: ImportValidQuestions[] = [];

  expanded = [];

  notValidExpanded = [];

  selectedQuestionsNo = 0;

  isConfirmSaveClicked = false;

  get headers() {
    return [
      {
        text: this.$t('tableHeaders.index'),
        sortable: false,
        align: 'center',
        value: 'index',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.questionTitle'),
        sortable: false,
        value: 'questionTitle',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.questionType'),
        sortable: false,
        align: 'center',
        value: 'questionType',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.subject'),
        sortable: false,
        align: 'center',
        value: 'subject',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.notes'),
        sortable: false,
        align: 'center',
        value: 'notes',
        class: this.headerClass,
      },
    ];
  }

  get notValidHeaders() {
    return [
      {
        text: this.$t('tableHeaders.index'),
        sortable: false,
        align: 'center',
        value: 'index',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.errorMessage'),
        sortable: false,
        align: 'center',
        value: 'message',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.questionData'),
        sortable: false,
        align: 'center',
        value: 'questionData',
        class: this.headerClass,
      },
    ];
  }

  get questionsDirection() {
    return this.languageData.id === SUPPORTED_LANGS.EN ? 'ltr' : 'rtl';
  }

  get isQuestionsNotSelected() {
    return this.validQuestions.length && this.selected.length === 0 && !this.isConfirmSaveClicked;
  }

  confirmSaveSelectedQuestions() {
    if (this.formateSelectedQuestions.length) {
      this.isConfirmSaveClicked = true;
      this.showSaveSelectedConfirm = false;
      this.$emit('uploadSelectedQuestions', this.formateSelectedQuestions);
      this.close();
    }
  }

  get formateSelectedQuestions() {
    return this.selected.map((selectedQuestion) => ({
      signature: selectedQuestion.signature,
      degree: selectedQuestion.degree,
      difficulty: selectedQuestion.difficulty,
      owner: selectedQuestion.owner,
      lang: selectedQuestion.lang,
      title: selectedQuestion.title,
      answers: selectedQuestion.answers,
      typeId: selectedQuestion.typeId,
      statusId: selectedQuestion.statusId,
      text: selectedQuestion.text,
      time: selectedQuestion.time,
    }));
  }

  close() {
    this.selected = [];
    this.expanded = [];
    this.notValidExpanded = [];
    this.showExportQuestionsDialog = false;
  }

  open(
    subjectData: { text: string; value: string },
    languageData: { name?: ServerTranslation; id?: string; value?: string },
    diffecultyLevel: Difficulty,
    validQuestions: ImportValidQuestions[],
    notValidQuestions: ImportNotValidQuestions[],
  ) {
    this.loading = true;
    this.isConfirmSaveClicked = false;
    this.subjectData = subjectData;
    this.languageData = languageData;
    this.diffecultyLevel = diffecultyLevel;
    this.validQuestions = validQuestions;
    this.notValidQuestions = notValidQuestions;
    this.showExportQuestionsDialog = true;
    this.loading = false;
  }

  getTypeName(typeId) {
    switch (typeId) {
      case this.questionTypesKeys.ESSAY.id:
        return this.questionTypesKeys.ESSAY.defaultText;
      case this.questionTypesKeys.MCQ.id:
        return this.questionTypesKeys.MCQ.defaultText;
      case this.questionTypesKeys.MMCQ.id:
        return this.questionTypesKeys.MMCQ.defaultText;
      case this.questionTypesKeys.CASE_STUDY.id:
        return this.questionTypesKeys.CASE_STUDY.defaultText;
      case this.questionTypesKeys.MATCHING.id:
        return this.questionTypesKeys.MATCHING.defaultText;
      default:
        return this.questionTypesKeys.T_F.defaultText;
    }
  }

  onExpand(event: { value: boolean }) {
    if (event.value) {
      setTimeout(() => {
        this.renderEquations();
      }, 0);
    }
  }

  exportNotValidQuestions() {
    if (this.notValidQuestions.length) {
      const defaultQuestionSeparator = '<p>----------</p>';
      let htmlElements = '';
      this.notValidQuestions.forEach((notValidQuestion) => {
        htmlElements += defaultQuestionSeparator;
        htmlElements += `<p>${notValidQuestion.originalText ?? notValidQuestion.questionData}</p>`;
      });
      Utils.EXPORT_EXAM(
        htmlElements,
        this.questionsDirection,
        this.languageData.value,
        `${this.subjectData?.text} notValidQuestions` || 'notValidQuestions',
      );
    }
  }
}
